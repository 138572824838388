import React from "react";
import { Link } from "react-router-dom";
import AsyncSelect from "react-select/async";

import DatePicker from "../shared/DatePicker";
import TaskPanel from "../shared/TaskPanel";
import FetchError from "../shared/FetchError";
import StateFilter from "../shared/StateFilter";
import { states } from "../../utils/states";
import { baseUrl } from "../../utils/baseUrl";
import { dateToString } from "../../utils/dates";

class RideReport extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      customerSelected: "",
      filter: 3,
      from: new Date(),
      to: new Date(),
      rides: [],
      value: "",

      disableExport: false,
      isError: false,
    };

    this.exportCSV = this.exportCSV.bind(this);
    this.filterOptions = this.filterOptions.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleFrom = this.handleFrom.bind(this);
    this.handleTo = this.handleTo.bind(this);
    this.loadOptions = this.loadOptions.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  filterOptions() {
    return this.state.customers.map((x, i) => ({
      value: x,
      label: x.name,
    }));
  }

  handleCustomerChange(selected) {
    if (!selected) {
      this.setState(
        {
          customerSelected: "",
        },
        () => this.refresh()
      );

      return;
    }

    const selectedOption = {
      value: selected.value,
      label: selected.label,
    };

    this.setState(
      {
        customerSelected: selected.value,
        selectedOption,
      },
      () => this.refresh()
    );
  }

  handleInputChange = (value) => {
    this.setState({
      value: value,
    });
  };

  handleFilter(e) {
    e.preventDefault();

    this.setState({
      filter: e.target.value,
    });
  }

  handleFrom = (from) => {
    this.setState(
      {
        from,
      },
      () => this.refresh()
    );
  };

  handleTo = (to) => {
    this.setState(
      {
        to,
      },
      () => this.refresh()
    );
  };

  async loadOptions() {
    try {
      let query = this.state.value;

      if (query.length === 0) {
        query = "a";
      }

      const url = `${baseUrl}/customers/truncated/?value=${query}`;
      const response = await fetch(url, {
        credentials: "include",
      });

      const result = await response.json();

      this.setState(
        {
          customers: result,
        },
        () => {
          this.filterOptions();
        }
      );

      return this.filterOptions(result);
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  async refresh() {
    const from = this.state.from;
    const to = this.state.to;

    if (!from || !to) {
      return;
    }

    const fromUrl = `${from.getFullYear()}-${
      from.getMonth() + 1
    }-${from.getDate()}`;
    const toUrl = `${to.getUTCFullYear()}-${to.getMonth() + 1}-${to.getDate()}`;

    const query = `from=${fromUrl}&to=${toUrl}`;

    if (this.state.customerSelected) {
      try {
        const id = this.state.customerSelected._id;
        const query = `from=${fromUrl}&to=${toUrl}&id=${id}`;

        const response = await fetch(`${baseUrl}/rides/customer?${query}`, {
          credentials: "include",
        });
        const result = await response.json();

        this.setState({
          rides: result.rides,
        });

        return;
      } catch {
        this.setState({
          isError: true,
        });
      }
    }

    try {
      const response = await fetch(`${baseUrl}/rides/all?${query}`, {
        credentials: "include",
      });
      const result = await response.json();

      this.setState({
        rides: result.rides,
      });
    } catch {
      this.setState({
        isError: true,
      });
    }
  }

  componentDidMount() {
    this.refresh();
    this.loadOptions();
  }

  render() {
    return (
      <div className="container">
        <div className="pb-2 mt-4 mb-4 border-bottom">
          <h2>Auswertung</h2>
        </div>

        <TaskPanel>
          <div>
            <div className="form-row d-flex">
              <div className="col-md-2 mr-1">
                <label>Von</label>
                <DatePicker date={this.state.from} onChange={this.handleFrom} />
              </div>
              <div className="col-md-2 mr-1">
                <label>Bis</label>
                <DatePicker date={this.state.to} onChange={this.handleTo} />
              </div>
              <div className="col-md-2 mr-1">
                <label>Status</label>
                <StateFilter
                  filter={this.state.filter}
                  handleFilter={this.handleFilter}
                />
              </div>
              <div className="col-md-4 mr-1">{this.renderCustomerSelect()}</div>
              <div className="mt-auto">
                <button className="btn btn-primary" onClick={this.exportCSV}>
                  Exportieren
                </button>
              </div>
            </div>
          </div>
        </TaskPanel>
        {this.state.isError ? this.renderFetchError() : this.renderTable()}
      </div>
    );
  }

  exportCSV() {
    const filter = parseInt(this.state.filter);
    let rides = this.state.rides;

    if (filter !== 3) {
      rides = rides.filter((x) => x.state === filter);
    }

    let sum;
    for (let i = 0; i < rides.length; i++) {
      if (i === 0) {
        sum = rides[i].quantity;

        continue;
      }

      sum += rides[i].quantity;
    }

    const rows = [];

    const headerRow = [
      "Kd.-Nr.",
      "Verbrauchsstelle",
      "Ort",
      "Strasse",
      "Haus-Nr.",
      "Kundenname",
      "Datum der Entsorgung",
      "Menge in m³",
      "EG",
      "Gesamtsumme:",
      sum,
    ];
    rows.push(headerRow);

    let dataRow;
    let quantity;
    for (let i = 0; i < rides.length; i++) {
      quantity = "" + rides[i].quantity;
      quantity = quantity.length > 1 ? quantity.replace(".", ",") : quantity;

      let lastIndex = rides[i].consumer.adress.street.lastIndexOf(" ");
      let street = rides[i].consumer.adress.street.substring(0, lastIndex);

      let streetNumber = rides[i].consumer.adress.street.split(' ');
      streetNumber = streetNumber[streetNumber.length - 1]

      dataRow = [
        `${
          rides[i].consumer.referenceNumber
            ? rides[i].consumer.referenceNumber
            : "n/v"
        }`,
        `${rides[i].consumer.consumptionSite}`,
        `${rides[i].consumer.adress.city}`,
        `${street}`,
        `${streetNumber}`,
        `${
          rides[i].consumer.company
            ? rides[i].consumer.company
            : rides[i].consumer.name
        }`,
        `${new Date(rides[i].date).toLocaleDateString()}`,
        `${quantity}`,
        `${rides[i].consumer.tavArea}`,
      ];

      rows.push(dataRow);
    }

    let csvContent =
      "data:text/csv;charset=utf-8,\uFEFF" + // Use universal BOM (Byte Order Mark) to force Excel to use UTF-8 isntead aof ANSI
      rows.map((x) => x.join(";")).join("\n");

    const from = new Date(this.state.from).toLocaleDateString();
    const to = new Date(this.state.to).toLocaleDateString();

    const name = `Entleerungen_${from}_${to}.csv`;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", name);
    document.body.appendChild(link); // Required for Firefox

    link.click();
  }

  renderCustomerSelect() {
    return (
      <div className="form-grup">
        <label htmlFor="customerSelect">Kunde</label>
        <span id="customerSelect">
          <AsyncSelect
            isClearable={true}
            loadOptions={this.loadOptions}
            noOptionsMessage={() =>
              "Zum Anzeigen von Vorschlägen, bitte nach Namen suchen"
            }
            onChange={this.handleCustomerChange}
            onInputChange={this.handleInputChange}
            placeholder={"Nach Namen suchen"}
          />
        </span>
      </div>
    );
  }

  renderEmptyWarning() {
    return (
      <tr>
        <td className="alert alert-info text-center" colSpan={8} role="alert">
          Für den gewählten Zeitraum oder Filter liegen keine Daten vor.
        </td>
      </tr>
    );
  }

  renderFetchError() {
    if (!this.state.isError) {
      return;
    }

    return <FetchError />;
  }

  renderTable() {
    return (
      <div className="table">
        <table className="table">
          <thead>
            <tr>
              <th>Name/Firma</th>
              <th>Ort</th>
              <th>Adresse</th>
              <th>Abfuhr (m³)</th>
              <th>Interv.</th>
              <th>Datum</th>
              <th>Status</th>
              <th>Auswahl</th>
            </tr>
          </thead>
          <tbody>{this.renderTableBody()}</tbody>
        </table>
      </div>
    );
  }

  renderTableBody() {
    if (!this.state.rides) {
      return;
    }

    const filter = parseInt(this.state.filter);
    let rides = this.state.rides;

    if (filter !== 3) {
      rides = rides.filter((x) => x.state === filter);
    }

    rides = rides.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });

    if (rides.length === 0) {
      return this.renderEmptyWarning();
    }

    const rows = rides.map((x, i) => (
      <tr key={i} id={i}>
        <td>
          {x.consumer &&
          x.consumer.company != null &&
          x.consumer &&
          x.consumer.company.length > 0
            ? x.consumer.company
            : x.consumer.name}
        </td>
        <td>
          {x.consumer.adress.zipcode} {x.consumer.adress.city}
        </td>
        <td>{x.consumer.adress.street}</td>
        <td>{x.quantity}</td>
        <td>{x.consumer.interval}</td>
        <td>{dateToString(x.date)}</td>
        <td>{states[x.state]}</td>
        <td>
          <Link
            to={`/fahrten/${x._id}`}
            className="btn btn-sm btn-outline-info"
          >
            Details
          </Link>
        </td>
      </tr>
    ));

    return rows;
  }
}

export default RideReport;
