import React, { forwardRef, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faCalendarWeek,
} from "@fortawesome/free-solid-svg-icons";

import DatePicker from "react-datepicker";

import "./item.scss";

const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
  <button className="btn btn-primary ml-1" onClick={onClick} ref={ref}>
    {new Date(value).toLocaleDateString()}
  </button>
));

const RidePreviewItem = ({
  address,
  date,
  handleSubmit,
  id,
  interval,
  name,
  pitSize,
}) => {
  const [dynamicInterval, setDynamicInterval] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submittedDate, setSubmittedDate] = useState();

  function handleDateChange(date) {
    handleSubmit(id, date, dynamicInterval);
    setIsSubmitted(true);
    setSubmittedDate(date);
  }

  function handleDynamicIntervalChange(e) {
    setDynamicInterval(e.target.checked);
  }

  return (
    <React.Fragment>
      <li
        className={`${
          isSubmitted ? "disabled" : ""
        } list-group-item preview-item d-flex justify-content-between align-items-start`}
      >
        <span className="mr-3">
          <FontAwesomeIcon fixedWidth={true} icon={faAddressBook} />{" "}
          {`${address.city}, ${address.street}, ${name}`}
        </span>
        <span className="badge ml-auto bg-preview-item-badge rounded-pill">
          <b id="pit-size-icon">m³</b> {pitSize}
        </span>
        <span className="badge ml-1 bg-preview-item-badge rounded-pill">
          <FontAwesomeIcon fixedWidth={true} icon={faCalendarWeek} />
          {interval}
        </span>
        <span className="ml-1">
          {isSubmitted ? (
            <button className="btn btn-secondary ml-1" disabled>
              {submittedDate.toLocaleDateString()}
            </button>
          ) : (
            <DatePicker
              selected={new Date(date)}
              onChange={handleDateChange}
              calendarClassName="date-picker-calendar"
              dayClassName={() => "date-picker-day"}
              popperPlacement="auto"
              customInput={<DatePickerInput />}
            >
              <div id="date-picker-children">
                <div className="custom-switch">
                  <input
                    type="checkbox"
                    id="dynamic-interval"
                    className="custom-control-input"
                    checked={dynamicInterval}
                    onChange={handleDynamicIntervalChange}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="dynamic-interval"
                  >
                    Dynamischer Intervall{" "}
                  </label>
                </div>
              </div>
            </DatePicker>
          )}
        </span>
      </li>
    </React.Fragment>
  );
};

export default RidePreviewItem;
