import React from "react";

const Footer = () => {
  return (
    <footer className="footer mt-5">
      <div className="container-fluid">
        <hr />
        <p>
          <small className="text-muted">
            Abwasser Express 1.7.0
            <br />
            Copyright 2022 © Abwasser Express
          </small>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
