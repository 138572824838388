import React from "react";

const StreetInput = (props) => {
  return (
    <div className="form-group">
      <label htmlFor="street">Strasse & Hausnummer</label>
      {" "}
      <small className="text-muted">(Straße & Hausnummer mit Leerzeichen trennen. Hausnummer mit Buchstaben zusammen schreiben. Von bis mit - verbinden. Bsp. Musterstr. 1a, Musterstr. 12-13)</small>
      <input
        type="text"
        className="form-control"
        id="street"
        value={props.street}
        onChange={props.handleChange}
        required={true}
      />
    </div>
  );
};

export default StreetInput;
